export const company =
{
    name: 'BullMark Properties',
    heroTitle: 'The Key to Your Dream Home',
    mainServiceArea: 'Pattaya',
    phone1: '+66 622728489 (ENG/TH)',
    // phone2: '081 969 4950 (ENG/CN)',
    email: 'info@bull-properties.com',
    address1: '1/22 Moo 7, Nongprue, Pattaya',
    address2: 'Chonburi, Thailand 20150',
    phoneUrl: '+66622728489',
    lineUrl: 'https://lin.ee/s8rdCsC',
    whatsappUrl: 'https://wa.me/66622728489',
    facebookUrl: 'https://www.facebook.com/bullmarkproperties',
    instagramUrl: 'https://www.instagram.com/bullmarkproperties',
    tiktokUrl: 'https://www.tiktok.com/@bullmarkproperties',
    youtubeUrl: 'https://www.youtube.com/@bullmarkproperties',
    linkedInUrl: '',
    OFFICE_LAT: 12.954054,
    OFFICE_LNG: 100.88996,
    refStarter: 'bm'
}
