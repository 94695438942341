'use client'
import React from 'react'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/navigation'
import { currencies } from '@constants'
import { getSearchParams } from "@utils";
import { Listbox } from '@headlessui/react'
import { getCookie, setCookie } from 'cookies-next';
import { MdCurrencyExchange } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

export default function MobileCurrencySwitcher({ lang }) {
    const router = useRouter();

    const [currency, setCurrencyType] = useState('thb')
    const [curren, setCurrency] = useState(currencies[lang][0])
    let [addChecked, setAddChecked] = useState(currencies[lang][0].value)

    useEffect(() => {
        let currencyType = getCookie('currency')
        if (currencyType == undefined) {
            currencyType = 'thb'
        } else {
            setCurrencyType(currencyType)
            setAddChecked(currencyType)
        }

    })

    const handleUpdateParams = (e) => {
        setCookie('currency', e.value)
        setCurrencyType(e.value)
        router.refresh()

    };

    useEffect(() => {
        const currencyValue = getSearchParams('currency')
        setAddChecked(currencyValue)

        const newValue = currencies[lang].find(obj => obj.value === addChecked);
        // console.log('new value ' + newValue)
        if (newValue != undefined) {
            setCurrency(newValue)
            setCurrencyType(newValue.value)

        }
    }, [handleUpdateParams])
  return (

    <div className="relative w-full">
    <Listbox 
        value={curren} 
        onChange={(value) => {
            setCurrency(value)
            handleUpdateParams(value)
        }}
    >
    <div className="w-full">
            <Listbox.Button className="w-full cursor-pointer text-maincolor text-center border-[1px] border-light border-solid p-1 flex items-center justify-between rounded-full mr-3">
                <div className='flex flex-row items-center'>
                    <MdCurrencyExchange color='maincolor' />
                    <span className="ml-2 truncate text-maincolor">{curren.value.toUpperCase()} - {curren.title}</span>
                </div>
                <span><IoIosArrowDown /></span>
            </Listbox.Button>
            <Listbox.Options className="w-full py-1 bg-light overflow-hidden rounded-md">
                {currencies[lang].map((curr) => (
                    <Listbox.Option
                    key={curr.value}
                    className={({ active }) =>
                        `w-full relative flex cursor-pointer rounded-full focus:outline-none ${
                        active ? 'w-full bg-maincolor text-light' : 'text-maincolor'
                        }`
                    }
                    value={curr}
                    >
                        <div className='w-full py-1 pl-2 rounded-full  hover:text-light'>
                            <span>
                                {curr.value.toUpperCase()} - {curr.title}
                            </span>
                        </div>
                    </Listbox.Option>
                ))}
            </Listbox.Options>
    </div>
    </Listbox>
</div>
  )
}